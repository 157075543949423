<template>
  <div class="page-content-wrap">
    <div class="banner">
      <div class="banner-info">
        <img src="../../assets/images/23487058.png" alt="">
        <div class="logo-name">龙虎山铭辉实弹射击基地</div>
        <div class="label"><span>实弹</span>国际标准建设的靶道24条</div>
        <div class="label"><span>射击</span>高素质安全员一对一的管家式服务</div>
      </div>
    </div>
    <div class="jieshao-box">
      <div class="jieshao">
        <div class="guanyu">关于</div>
        <div class="About"><strong>About</strong></div>
        
        <div class="editableContent">
          <div>江西龙虎山铭辉射击有限公司成立于2017年，目前是鹰潭市文化旅游投资发展集团有限公司的下属混改制企业。</div>
          <div>是我省唯一集国防动员、国防教育、射击职业培训、体育拓展、体育竞赛、生态旅游观光、休闲度假、健身娱乐为一体的综合性生态实训、运动基地，也是全国射击设施条件、射击健身休闲接待能力等综合条件第一梯队成员之一，且为省内唯一能承接国家级赛事的民营基地。</div>
          <div>在对国防办的实训和专业射击射箭运动员提供训练、赛事服务的同时，探索建立了“旅、健、学、乐”为一体的综合性国防+体育+旅游的运营模式。</div>
          <div>基地占地总面积为1300余亩，总建筑面积约20000平方米，内设综合接待区、实弹射击区。面向广大射击爱好者开放了射击、射箭、足球、田径、卡丁车等项目，还开设了户外拓展、户外营地、国防教育等业务板块，已成功举办多次省级、国家级职业射击赛事，成功发展俱乐部会员700余人。专业和业余市场均辐射全国。</div>
          <div>实弹射击项目分为室内射击区和室外射击区，拥有国际标准建设的靶道24条。共20条室内靶道，4条室外移动飞碟射击靶道。配备高素质安全员，实现一对一的管家式服务，让所有参与射击的会员可安全、畅快、舒适的体验枪械射击的魅力。</div>
        </div>
      </div>
      <img src="../../assets/images/23698943.jpg" class="img">
    </div>
    <div class="yibuFrameContent">
      <div class="left-img"></div>
      <div class="right">
        <div class="content-inner">
          <div class="inner-item0">
            <div class="text">企业团建、拓展培训、国防教育</div>
            <div class="w-line"></div>
          </div>
          <div class="inner-item" v-for="(item, index) in yibuList" :key="index">
            <div class="inner-item-left">{{ item.value }}</div>
            <div class="inner-item-right">
              <div class="text">{{ item.text }}</div>
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="yewu-wrap">
      <div class="yewu-title">业务</div>
      <div class="yewu-title-english"><strong>Business</strong></div>
      <div class="yewuList">
        <div class="yewuList-item" v-for="(item, index) in yewuList" :key="index" @click="handleToIntroducePage(item)">
          <img :src="item.icon" class="icon">
          <div class="text">{{ item.text }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
    <div class="sheshi-wrap-box">
      <div class="sheshi-wrap">
        <div class="sheshi-title">基地设施</div>
        <div class="sheshi-title-english"><strong>Facilities</strong></div>
        <div class="sheshi-desc">基地现设有实弹射击馆、综合训练馆、户外飞碟靶场、户外足球场等一系列活动项目设施。除此之外，基地内设有三层客房、会议室三个、餐厅1间。是企业团建、拓展培训、国防教育的首选之地。</div>
        <div class="sheshiList">
        <div class="sheshiList-item" v-for="(item, index) in sheshiList" :key="index">
          <img :src="item.icon" class="icon">
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      yibuList: [
        { value: 24, text: '条国际标准靶道', desc: '20条室内靶道；4条室外移动飞碟射击靶道'},
        { value: 1300, text: '亩占地总面积', desc: '景区地处龙虎山风景区气候宜人，有天然氧吧之称'},
        { value: 20000, text: '平总建筑面积', desc: '设综合接待区、实弹射击区'},
      ],
      yewuList: [
        { icon: require('../../assets/images/23537260.jpg'), text: '室内小口径', desc: 'Small shooting', key: 'shinei'},
        { icon: require('../../assets/images/23537184.jpg'), text: '室外飞碟', desc: 'Trap Shooting', key: 'shiwai'},
        { icon: require('../../assets/images/-50571.png'), text: '枪支介绍', desc: 'Introduction to guns', key: 'xiaokoujing'},
        { icon: require('../../assets/images/23549233.jpg'), text: '国防教育', desc: 'National Defense', key: 'guofang'},
        { icon: require('../../assets/images/23549437.jpg'), text: '红色党建', desc: 'Red party building', key: 'dangjian'},
      ],
      sheshiList: [
      { icon: require('../../assets/images/23549894.jpeg'), text: '基地客房'},
      { icon: require('../../assets/images/23549963.jpg'), text: '基地餐厅'},
      { icon: require('../../assets/images/23549961.png'), text: '基地会议室'},
      { icon: require('../../assets/images/23549970.jpg'), text: '基地球场'},
      ]
    }
  },
  methods: {
    handleToIntroducePage(item) {
      this.$router.push({ path: '/serve-introduce', query: { key: item.key } });
    },
    handleToPage(item) {
      this.$router.push(item.path);
    }
  },
}
</script>

<style lang="scss" scoped>
.page-content-wrap {
  position: relative;
  .banner {
    width: 100%;
    height: 900px;
    background-image: url(../../assets/images/banner1.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    .banner-info {
      position: absolute;
      top: 50%;
      left: 20%;
      img {
        width: 576px;
        height: 192px;
      }
      .logo-name {
        margin-top: -50px;
        font-size: 54px;
        line-height: 150%;
        color: #fff;
      }
      .label {
        font-size: 18px;
        line-height: 1.5;
        color:rgb(238, 238, 238);
        span {
          color: rgb(187, 187, 187);
          padding-right: 10px;
        }
      }
    }
  }
  .jieshao-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 70px 0;
    .img {
      width: 40vw;
      height: 600px;
    }
    .jieshao {
      width: 50vw;
      padding: 0 40px 0 20px;
      font-family: Arial,Helvetica,sans-serif;
      color: #333;
      .guanyu {
        width: 60px;
        font-size: 28px;
        padding-bottom: 10px;
        border-bottom: 2px solid #333;
      }
      .About {
        font-size: 108px;
        padding: 10px 0;
      }
      .editableContent {
        div {
          text-indent: 2em;
          font-size:12.0pt;
          line-height: 30px;
          color: #666;
        }
      }
    }
  }
  .yibuFrameContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 774px;
    .left-img {
      width: 50%;
      height: 100%;
      background-image: url(../../assets/images/24993519.jpg);
      background-repeat: repeat;
      background-position: 50% 50%;
      background-size: cover;
    }
    .right {
      width: 50%;
      height: 100%;
      background-image: url(../../assets/images/50567.jpg);
      background-repeat: repeat;
      background-position: 50% 50%;
      background-size: cover;
      padding: 0 7%;
      .content-inner {
        // width: 600px;
        margin: 0 auto;
        font-size: 14px;
        color: #555;
        .inner-item0 {
          margin-top: 50px;
          display: flex;
          align-items: flex-end;
          .w-line {
            flex: 1;
            height: 0px;
            border: 0.5px solid #5b5b5b;
            margin-left: 20px;
          }
        }
        .inner-item {
          padding-top: 70px;
          display: flex;
          align-items: flex-end;
          padding-bottom: 50px;
          border-bottom: 0.5px solid #5b5b5b;
          &:last-child {
            border-bottom: 0px;
          }
          .inner-item-left {
            font-family:Arial,Helvetica,sans-serif;
            font-size: 96px;
            color: #fff;
            font-weight: 500;
          }
          .inner-item-right {
            padding-bottom: 15px;
            padding-left: 10px;
            .text {
              font-size: 20px;
              color: #eee;
            }
            .desc {
              font-size: 14px;
              color: #999;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  .yewu-wrap {
    width: 70%;
    margin: 0 auto;
    margin-top: 60px;
    color: #333;
    .yewu-title {
      width: 60px;
      font-size: 28px;
      padding-bottom: 10px;
      border-bottom: 2px solid #333;
    }
    .yewu-title-english {
      font-size: 108px;
      padding: 10px 0;
    }
    .yewuList {
      padding: 50px 0;
      padding-bottom: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .yewuList-item {
        cursor: pointer;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 0.5px solid #eee;
        &:last-child {
          border: 0px;
        }
        .icon {
          width: 46px;
          height: 46px;
        }
        .text {
          font-size: 16px;
          color: #000;
          padding: 10px 0 5px 0;
        }
        .desc {
          color: #999;
          font-size: 12px;
        }
      }
    }
  }
  .sheshi-wrap-box {
    width: 100%;
    padding: 30px 0;
    background-color: #f5f5f5;
    .sheshi-wrap {
      width: 70%;
      margin: 0 auto;
      margin-top: 60px;
      color: #333;
      .sheshi-title {
        width: 115px;
        font-size: 28px;
        padding-bottom: 10px;
        border-bottom: 2px solid #333;
      }
      .sheshi-title-english {
        font-size: 108px;
        padding: 10px 0;
      }
      .sheshi-desc {
        padding: 20px 0;
        color: #555;
        font-size: 16px;
        line-height: 2;
      }
      .sheshiList {
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .sheshiList-item {
          width: 48.5%;
          background-color: #fff;
          margin-bottom: 40px;
          .icon {
            width: 100%;
          }
          .text {
            padding: 12px 0;
            text-align: center;
            font-size: 18px;
            color: #555;
          }
        }
      }
    }
  }
  @media (max-width: 767.98px) {
    .banner {
      height: 400px;
      .banner-info {
        position: absolute;
        top: 45%;
        left: -45%;
        width: 800px;
        transform: scale(0.4);
      }
    }
    .jieshao-box {
      padding: 20px 20px;
      .img {
        display: none;
      }
      .jieshao {
        width: 100%;
        padding: 0;
      }
    }
    .yibuFrameContent {
      display: none;
    }
    .yewu-wrap {
      width: 90%;
      margin-top: 0px;
      .yewu-title-english {
        display: none;
      }
      .yewuList {
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-top: 20px;
        padding-bottom: 50px;
       .yewuList-item {
        width: 33.33%;
        border: 0px;
        margin-top: 20px;
       }
      }
    }
    .sheshi-wrap-box {
      .sheshi-wrap {
        width: 90%;
        margin-top: 0px;
        .sheshi-title-english {
          display: none;
        }
        .sheshiList-item {
          margin-bottom: 20px !important;
          .text {
            padding: 8px 0 !important;
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
</style>