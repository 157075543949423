<template>
  <div class="content-wrap">
    <div class="introduce-imglist">
      <div class="introduce-item-img" v-for="(item, index) in introduceImg" :key="index">
        <img :src="item" alt="">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      introduceImg: [
        require('../../assets/images/servepage/11.jpeg'),
        require('../../assets/images/servepage/22.jpeg'),
        require('../../assets/images/servepage/33.jpeg'),
        require('../../assets/images/servepage/44.jpeg'),
        require('../../assets/images/servepage/Snipaste_2024-05-07_1.jpeg'),
        require('../../assets/images/servepage/Snipaste_2024-05-07_2.jpeg'),
        require('../../assets/images/servepage/66.jpeg'),
        require('../../assets/images/servepage/77.jpeg'),
        require('../../assets/images/servepage/88.jpeg'),
        require('../../assets/images/servepage/99.jpeg'),
        require('../../assets/images/servepage/12.jpeg'),
        require('../../assets/images/servepage/13.jpeg'),
        require('../../assets/images/servepage/14.jpeg'),
        require('../../assets/images/servepage/15.jpeg'),
        require('../../assets/images/servepage/16.jpeg'),
        require('../../assets/images/servepage/17.jpeg'),
        require('../../assets/images/servepage/18.jpeg'),
        require('../../assets/images/servepage/20.jpeg'),
        require('../../assets/images/servepage/21.jpeg'),
        require('../../assets/images/servepage/31.jpeg'),
        require('../../assets/images/servepage/41.jpeg'),
        require('../../assets/images/servepage/51.jpeg'),
        require('../../assets/images/servepage/61.jpeg'),
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.content-wrap {
  position: relative;
  .introduce-imglist {
    display: flex;
    flex-direction: column;
    align-items: center;
    .introduce-item-img {
      img {
        width: 100%;
        display: flex;  
        gap: 0; /* 用于Flexbox和Grid的间距属性 */
      }
    }
  }
  @media (max-width: 767.98px) {
    
  }
}
</style>