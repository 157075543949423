<template>
  <div class="header">
    <nav class="nav-wrapper-phone">
      <div class="header-info">
        <a href="#" class="menu">
          <img src="@/assets/images/menu.png" style="width:28px;" alt="menu" @click.stop="showMenuFunc()">
        </a>
        <div class="logo" @click.stop="handleToHome()"></div>
        <div class="head-menu" :class="{'head-menu-active': showMenu}" v-click-outside="handleClickOutside">
          <div class="menu-item" v-for="(item, index) in menuList" :key="index" @click.stop="handleToPage(item)">{{item.name}}</div>
        </div>
      </div>
    </nav>
    <nav class="nav-wrapper-pc">
      <div class="logo" @click.stop="handleToHome()"></div>
      <div class="head-right">
        <div class="menu-item" v-for="(item, index) in menuList" :key="index" @click.stop="handleToPage(item)">{{item.name}}</div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
 data() {
  return {
    showMenu: false,
    menuList:[
      { name: '首页', path: '/'},
      { name: '服务', path: '/serve'},
      { name: '产品手册', path: '/shouce'},
      { name: '国防动员实训基地', path: '/map'},
    ]
  }
 },
 methods: {
  handleClickOutside() {
    this.showMenu = false
  },
  showMenuFunc() {
    this.showMenu = !this.showMenu
  },
  handleToHome() {
    this.showMenu = false
    this.$router.push('/');
  },
  handleToPage(item) {
    this.showMenu = false
    this.$router.push(item.path);
  }
 },
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  z-index: 9999999;
  width: 100%;
  box-shadow: 0px 10px 10px -10px rgba(0, 0, 0,0.3);
}
.nav-wrapper-phone {
  display: none;
  background-color: #fff;
  color: #000;
  .header-info {
    display: flex;
    align-items: center;
    padding: 16px 5.6%;
  }

  a > img {
    vertical-align: middle;
  }
  .logo {
    cursor: pointer;
    width: 385px;
    height: 55px;
    background-image: url('../assets/images/WechatIMG77.jpeg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-left: 20px;
  }
  .head-menu {
    position: fixed;
    left: -40%;
    top: 87px;
    width: 40%;
    height: calc(100vh - 88px);
    background-color: #fff;
    transition: left 0.3s ease-in-out;
    .menu-item {
      padding: 10px 0px 10px 20px;
    }
  }
  .head-menu-active {
    left: 0;
  }
}

.nav-wrapper-pc {
  display: none;
  height: 80px;
  padding: 0px 30px;
  font-size: 14px;
  background-color: #fff;

  .head-right {
    display: flex;
    align-items: center;
    .menu-item {
      cursor: pointer;
      margin-right: 20px;
      color: #383838;
      &:hover {
        color: #059FE9;
      }
    }
  }
  .logo {
    cursor: pointer;
    width: 385px;
    height: 55px;
    background-image: url('../assets/images/WechatIMG77.jpeg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin-left: 20px;
  }
}

@media (max-width: 767.98px) {
  .nav-wrapper-phone {
    display: block;
  }
}

@media (min-width: 768px) {
  .nav-wrapper-pc {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>