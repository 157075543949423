<template>
  <div class="serve-introduce-page">
    <el-carousel class="carouse-box" v-if="currentData.imgs">
      <el-carousel-item v-for="item in currentData.imgs" :key="item" class="banner-item">
        <div class="banner-item" :style="{backgroundImage: 'url('+ item +')' }"></div>
      </el-carousel-item>
    </el-carousel>
    <div class="serve-wrap-box">
      <div class="title">{{ currentData.title }}</div>
      <div class="introduce" v-if="currentData.introduce">
        <div class="introduce-item" v-for="(item, index) in currentData.introduce" :key="index">
        <div class="label">{{ item.title }}</div>
        <div class="text">{{ item.text }}</div>
        </div>
      </div>
      <div class="introduce introduce-imglist" v-if="currentData.introduceImg">
        <div class="introduce-item-img" v-for="(item, index) in currentData.introduceImg" :key="index">
        <img :src="item" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentData: {},
      dataList: {
        'shinei': {
          imgs: [
            require('../../assets/images/servepage/xiao1.jpg'),
            require('../../assets/images/servepage/xiao2.jpg'),
            require('../../assets/images/servepage/xiao3.jpg'),
            require('../../assets/images/banner1.jpg'),
            require('../../assets/images/servepage/xiao4.jpg'),
            require('../../assets/images/servepage/xiao5.jpg'),
            require('../../assets/images/servepage/xiao6.jpg'),
          ],
          title: '室内小口径射击',
          introduce: [
            { title: '室内小口径射击：', text: '小口径胸环靶射击、静物射击，采用6靶道设计和国内最先进的悬挂系统控制靶和超声报靶设备。' },
            { title: '使用枪支：', text: '贝加尔 MP-161K/5.6mm口径，捷克CZ512步枪 口径：5.6mm，AKEM自动步枪 口径：5.6mm。' },
          ],
        },
        'shiwai': {
          imgs: [
            require('../../assets/images/servepage/23698939.jpg'),
            require('../../assets/images/servepage/23698949.jpg'),
            require('../../assets/images/servepage/23698953.jpg'),
            require('../../assets/images/servepage/23698942.jpg'),
            require('../../assets/images/servepage/23698943.jpg'),
            require('../../assets/images/servepage/23698947.jpg'),
          ],
          title: '室外猎枪射击',
          introduce: [
            { title: '室外猎枪射击：', text: '使用猎枪进行移动飞碟靶的射击。' },
            { title: '使用枪支：', text: '贝加尔 MP-27EM-1C/18.4mm口径，弹容量2发；贝雷塔A400/18.4mm口径，弹容量4发。' },
          ],
        },
        'xiaokoujing': {
          imgs: [
            require('../../assets/images/servepage/q1.jpg'),
            require('../../assets/images/servepage/q2.jpg'),
            require('../../assets/images/servepage/q3.jpg'),
          ],
          title: '小口径步枪',
          introduce: [
            { title: '小口径胸环靶射击、静物射击使用枪支：', text: '' },
            { title: '', text: '贝加尔 MP-161K/5.6mm口径，捷克CZ512步枪 口径：5.6mm，AKEM自动步枪 口径：5.6mm。' },
          ],
        },
        'lieqiang': {
          imgs: [
            require('../../assets/images/servepage/l1.jpg'),
            require('../../assets/images/servepage/l2.jpg'),
          ],
          title: '猎枪',
          introduce: [
            { title: '室外猎枪射击使用枪支：', text: '' },
            { title: '', text: '贝加尔 MP-27EM-1C/18.4mm口径，弹容量2发。' },
          ],
        },
        'guofang': {
          imgs: [
            require('../../assets/images/servepage/23699190.jpg'),
            require('../../assets/images/servepage/WechatIMG223.jpeg'),
            require('../../assets/images/servepage/WechatIMG224.jpeg'),
            require('../../assets/images/servepage/WechatIMG225.jpeg'),
          ],
          title: '国防教育',
          introduce: [
            { title: '国防教育及其他团建项目体验：', text: '' },
            { title: '', text: '主要针对于学生研学、国防教育项目、企业团建、家庭游玩、旅游团等团体参与，根据不同类型团体进行个性化方案的定制以及游戏环节的设计，在体验的同时能更好的提升团队的凝聚力。' },
          ],
        },
        'dangjian': {
          imgs: [
            require('../../assets/images/servepage/23699308.jpg'),
            require('../../assets/images/servepage/WechatIMG223.jpeg'),
            require('../../assets/images/servepage/WechatIMG224.jpeg'),
            require('../../assets/images/servepage/WechatIMG225.jpeg'),
          ],
          title: '红色党建',
          introduceImg: [
            { title: '红色党建体验：', text: '' },
            { title: '', text: '主要针对于学生研学、国防教育项目、企业团建、家庭游玩、旅游团等团体参与，根据不同类型团体进行个性化方案的定制以及游戏环节的设计，在体验的同时能更好的提升团队的凝聚力。' },
          ],
        },
        'cs': {
          imgs: [
            require('../../assets/images/servepage/c1.jpg'),
            require('../../assets/images/servepage/c2.jpg'),
            require('../../assets/images/servepage/c3.jpg'),
            require('../../assets/images/servepage/c4.jpg'),
            require('../../assets/images/servepage/c5.jpg'),
          ],
          title: '真人CS',
          introduce: [
            { title: '真人CS体验：', text: '' },
            { title: '', text: '包含对抗模式、夺旗模式、混战模式等多种作战模式。' },
          ],
        },
        'zhinan': {
          title: '项目收费',
          introduceImg: [
            require('../../assets/images/servepage/WechatIMG274.jpeg'),
            require('../../assets/images/servepage/WechatIMG14514.jpeg'),
          ],
        },
      }
    }
  },
  created() {
    const key = this.$route.query.key
    this.currentData = this.dataList[key]
  },
  methods: {
    handleToIntroducePage(item) {
      this.$router.push({ path: item.path, query: { text: item.text } });
    }
  },
}
</script>
<style lang="scss" scoped>
.serve-introduce-page {
  width: 950px;
  margin: 0 auto;
  .carouse-box {
    width: 100%;
    margin: 0 auto;
    margin-top: 40px;
    /deep/ .el-carousel__container {
      width: 100%;
      height: 400px;
    }
    .banner-item {
      width: 100%;
      height: 100%;
      background-position: 100%;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .serve-wrap-box {
    .title {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      font-family: 'Microsoft YaHei';
      color: #404040;
      font-size: 22px;
      font-weight: bold;
      font-style: normal;
      line-height: 50px;
      padding: 30px 0;
    }
    .introduce {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      .introduce-item {
        display: flex;
        align-items: center;
        color: #404040;
        font-size: 16px;
        line-height: 200%;
        .label {
          font-weight: 700;
        }
      }
    }
    .introduce-imglist {
      display: flex;
      flex-direction: column;
      align-items: center;
      .introduce-item-img {
        img {
          width: 827px;
        }
      }
    }
  }
}
@media (max-width: 767.98px) {
  .serve-introduce-page {
    width: 100%;
    .carouse-box {
      /deep/ .el-carousel__container {
        height: 300px !important;
      }
      /deep/ .el-carousel__button {
        width: 15px !important;
      }
    }
    .serve-wrap-box {
      .title {
        padding-bottom: 0;
      }
      .introduce {
        padding: 20px;
        .introduce-item {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      .serve-wrap {
        width: 90% !important;
        margin-top: 0px !important;
        .serve-title-english {
          display: none;
        }
        .serveList-item {
          margin-bottom: 20px !important;
          .mask {
            width: 100%;
            height: 100% !important;
            top: 0 !important;
            background-color: rgba(0,0,0,0.3) !important;
            color: #f5e5e5 !important;
          }
        }
      }
      .introduce-imglist {
        .introduce-item-img {
          img {
            width: 100vw !important;
          }
        }
      }
    }
  }
}
</style>