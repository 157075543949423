import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/', component: () => import('@/pages/Home/index.vue') },
    { path: '/serve', component: () => import('@/pages/Serve/index.vue') },
    { path: '/serve-introduce', component: () => import('@/pages/ServeIntroduce/index.vue') },
    { path: '/shouce', component: () => import('@/pages/Showce/index.vue') },
    { path: '/map', component: () => import('@/pages/Map/index.vue') },
    // { path: '*', meta: { requireAuth: false }, component: () => import('@/views/404.vue') }
  ]
});

router.beforeEach((to, from, next) => {
  // 在路由变化之前，滚动到页面顶部
  window.scrollTo(0, 0);
  next();
});
export default router
