<template>
  <div id="app">
    <AppNavbar />
    <div class="page-wrap">
      <router-view />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppNavbar from "@/layout/AppNavbar";
import AppFooter from '@/layout/AppFooter'
export default {
  name: 'app',
  components: {
    AppNavbar,
    AppFooter,
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.page-wrap {
  padding-top: 88px;
  flex: 1;
}
// @media (max-width: 767.98px) {
//   .page-wrap {
//     padding-top: 60px;
//   }
// }
</style>
