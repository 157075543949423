<template>
  <div class="serve-page">
    <el-carousel class="carouse-box">
      <el-carousel-item v-for="item in bannerList" :key="item" class="banner-item">
        <div class="banner-item" :style="{backgroundImage: 'url('+ item.img +')' }"></div>
      </el-carousel-item>
    </el-carousel>
    <div class="serve-wrap-box">
      <div class="serve-wrap">
        <div class="serve-title">基地设施</div>
        <div class="serveList">
        <div class="serveList-item" v-for="(item, index) in serveList" :key="index" @click="handleToIntroducePage(item)">
          <img :src="item.icon" class="icon">
          <div class="mask">{{ item.text }}</div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bannerList: [
        { img: require('../../assets/images/servepage/23698907.jpg')},
        { img: require('../../assets/images/servepage/23699294.jpg')},
      ],
      serveList: [
        { icon: require('../../assets/images/servepage/23698924.jpg'), text: '室内小口径射击', key: 'shinei'},
        { icon: require('../../assets/images/servepage/23698943.jpg'), text: '室外猎枪射击', key: 'shiwai'},
        { icon: require('../../assets/images/servepage/23699116.jpg'), text: '小口径枪支介绍', key: 'xiaokoujing'},
        { icon: require('../../assets/images/servepage/23699152.jpg'), text: '猎枪介绍', key: 'lieqiang'},
        { icon: require('../../assets/images/servepage/23699190.jpg'), text: '国防教育', key: 'guofang'},
        { icon: require('../../assets/images/servepage/23699308.jpg'), text: '红色党建', key: 'dangjian'},
        { icon: require('../../assets/images/servepage/23699451.jpg'), text: '真人CS', key: 'cs'},
        { icon: require('../../assets/images/servepage/WechatIMG218.jpeg'), text: '项目消费指南', key: 'zhinan'},
      ]
    }
  },
  methods: {
    handleToIntroducePage(item) {
      this.$router.push({ path: '/serve-introduce', query: { key: item.key } });
    }
  },
}
</script>
<style lang="scss" scoped>
.serve-page {
  width: 100%;
  .carouse-box {
    /deep/ .el-carousel__container {
      width: 100%;
      height: 500px;
    }
  }
  .banner-item {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .serve-wrap-box {
    width: 100%;
    padding: 30px 0;
    .serve-wrap {
      width: 60%;
      margin: 0 auto;
      margin-top: 60px;
      color: #333;
      .serve-title {
        width: 115px;
        font-size: 28px;
        padding-bottom: 10px;
        border-bottom: 2px solid #333;
      }
      .serve-title-english {
        font-size: 108px;
        padding: 10px 0;
      }
      .serve-desc {
        padding: 20px 0;
        color: #555;
        font-size: 16px;
        line-height: 2;
      }
      .serveList {
        width: 100%;
        margin: 0 auto;
        margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .serveList-item {
          cursor: pointer;
          width: 40%;
          // height: 333px;
          margin-bottom: 40px;
          overflow: hidden;
          position: relative;
          .icon {
            width: 100%;
            height: 100%;
            transition: transform .3s; /* 动画效果 */
          }
          .mask {
            width: 100%;
            height: 0;
            position: absolute;
            top: -10px;
            bottom: 0;
            left: 0;
            background-color: rgba(0,0,0,0.6);
            transition: height 0.3s ease-in-out;
            color: #fff;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &:hover {
            .icon {
              transform: scale(1.5); /* (150% 放大) */
            }
            .mask {
              top: 0;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767.98px) {
  .serve-page {
    .carouse-box {
      /deep/ .el-carousel__container {
        height: 300px;
      }
    }
  }
  .serve-wrap-box {
    .serve-wrap {
      width: 90% !important;
      margin-top: 0px !important;
      .serve-title-english {
        display: none;
      }
      .serveList-item {
        margin-bottom: 20px !important;
        .mask {
          width: 100%;
          height: 100% !important;
          top: 0 !important;
          background-color: rgba(0,0,0,0.3) !important;
          color: #f5e5e5 !important;
        }
      }
    }
  }
}
</style>