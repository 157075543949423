<template>
  <footer>
    <div class="footer-wrapper">
     <div class="footer-center">
      <div class="logo">龙虎山铭辉实弹射击基地</div>
      <div class="menu-box">
        <div class="menu-item">地址：江西鹰潭市龙虎山风景名胜区鱼塘村委会孔家小组</div>
        <div class="menu-item">电话：0701-6289998</div>
        <div class="menu-item">版权所有 ©江西龙虎山铭辉射击有限公司</div>
      </div>
     </div>
     <div class="wechat">
      <img src="../assets/images/servepage/Snipaste_2024-04-29_10-53-40.jpeg" alt="">
     </div>
    </div>
    <div class="beian">
      <a href="https://beian.miit.gov.cn/?spm=a313x.home_index.i7.3.58a33a81QXxJvw" target="_blank" class="beian-id">赣ICP备2024032373号-1</a>
     </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  background: #212222;
  color: #fff;
}
.footer-wrapper {
  width: 100%;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .footer-center {
    .logo {
      margin-bottom: 20px;
      font-size: 20px;
    }
    .menu-box {
      color: #999999;
      .menu-item {
        padding: 5px 0;
      }
    }
  }
  .wechat {
    display: block;
    width: 120px;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.beian {
  width: 100%;
  text-align: center;
  padding: 15px 0;
  background-color: #fff;
  a {
    color: rgb(152, 152, 152);
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .footer-wrapper {
    padding-bottom: 20px;
    flex-direction: column;
    .footer-center {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .wechat {
      margin-top: 20px;
      width: 100px;
      height: 100px;
    }
  }
}
</style>