import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/styles/reset.scss'
Vue.config.productionTip = false
import { Carousel, CarouselItem } from 'element-ui';
import ClickOutside from 'vue-click-outside';

Vue.directive('click-outside', ClickOutside);
Vue.use(Carousel)
Vue.use(CarouselItem)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

